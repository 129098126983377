import { Create, SimpleForm, NumberInput, TextInput } from "react-admin";

const PromoCreate = props => (
    <Create {...props} redirect="list">
        <SimpleForm>
            <TextInput source="name" />
            <NumberInput source="campaignId" />
            <NumberInput source="visaProductId" />
            <NumberInput source="mcProductId" />
        </SimpleForm>
    </Create>
);

export default PromoCreate;
