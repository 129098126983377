import { Create, SimpleForm, BooleanInput, NumberInput, TextInput } from "react-admin";

const MidCreate = props => (
    <Create {...props} redirect="list">
        <SimpleForm>
            <NumberInput source={"merchantId"} label={"Merchant Id"} />
            <TextInput source={"name"} />
            <TextInput source={"type"} />
            <BooleanInput source={"active"} defaultValue={true} />
            <BooleanInput source={"is3ds"} />
            <BooleanInput source={"upgradeTo3ds"} />
            <BooleanInput source={"downgradeToNon3ds"} />
        </SimpleForm>
    </Create>
);

export default MidCreate;
