import {
  HydraAdmin,
  fetchHydra as baseFetchHydra,
  hydraDataProvider as baseHydraDataProvider,
  ResourceGuesser
} from "@api-platform/admin";
import { parseHydraDocumentation } from "@api-platform/api-doc-parser";
import authProvider from "./authProvider";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import LoyaltyIcon from '@mui/icons-material/Loyalty';
import PaidIcon from "@mui/icons-material/Paid";
import PeopleIcon from "@mui/icons-material/People";
import ArchiveIcon from "@mui/icons-material/Archive";
import MidCreate from "./mids/MidCreate";
import MidEdit from "./mids/MidEdit";
import MidList from "./mids/MidList";
import PaymentTransactionEdit from "./payment_transactions/PaymentTransactionEdit";
import PaymentTransactionList from "./payment_transactions/PaymentTransactionList";
import UserList from "./users/UserList";
import PromoList from "./promos/PromoList";
import PromoCreate from "./promos/PromoCreate";
import PromoEdit from "./promos/PromoEdit";

const entrypoint = process.env.REACT_APP_API_ENTRYPOINT;

const getHeaders = () => sessionStorage.getItem("auth-token") ? {
  Authorization: `Bearer ${sessionStorage.getItem("auth-token")}`,
} : {};
const fetchHydra = (url, options = {}) =>
  baseFetchHydra(url, {
    ...options,
    headers: {...options.headers, ...getHeaders()},
  });
const apiDocumentationParser = () => async () => {
  try {
    return await parseHydraDocumentation(entrypoint, { headers: getHeaders });
  } catch (result) {
    const { api, response, status } = result;
    if (status !== 401 || !response) {
      throw result;
    }

    // Prevent infinite loop if the token is expired
    sessionStorage.removeItem("auth-token");

    return {
      api,
      response,
      status,
    };
  }
};

const baseDataProvider = () => baseHydraDataProvider({
  entrypoint: entrypoint,
  httpClient: fetchHydra,
  apiDocumentationParser: apiDocumentationParser(),
  useEmbedded: true,
  mercure: true,
});

export const dataProvider = {
  ...baseDataProvider(),
  rpcCall: (url, options) => fetchHydra(url, options)
}

const AdminPage = () => {
  return (
      <HydraAdmin
        requireAuth
        dataProvider={dataProvider}
        entrypoint={entrypoint}
        authProvider={authProvider}
      >
          <>
            <ResourceGuesser name="payment_transactions" list={PaymentTransactionList} edit={PaymentTransactionEdit} icon={PaidIcon} options={{ label: 'Transactions' }} />
            <ResourceGuesser name="payment_transaction_histories" icon={ArchiveIcon} options={{ label: 'History' }} />
            <ResourceGuesser name="mids" list={MidList} create={MidCreate} edit={MidEdit} icon={AccountBalanceIcon} />
            <ResourceGuesser name="promos" list={PromoList} create={PromoCreate} edit={PromoEdit} icon={LoyaltyIcon} />
            <ResourceGuesser name="users" list={UserList} icon={PeopleIcon} />
          </>
      </HydraAdmin>
  )};

export default AdminPage;
