import { Edit, TopToolbar, SimpleForm, Toolbar, SaveButton, ReferenceInput, NumberInput } from "react-admin";

// Disabled default Show action
const PaymentTransactionEditActions = () => (
    <TopToolbar>
    </TopToolbar>
);

// Disabled Delete button
const PaymentTransactionEditFormActions = () => (
  <Toolbar>
    <SaveButton />
  </Toolbar>
);

const PaymentTransactionEdit = props => (
    <Edit {...props} actions={<PaymentTransactionEditActions />} mutationMode="pessimistic">
        <SimpleForm toolbar={<PaymentTransactionEditFormActions />}>
            <ReferenceInput source="mid" reference={"mids"} />
            <NumberInput source="metadata.productId" label="Product Id" />
            <NumberInput source="metadata.campaignId" label="Campaign Id" />
        </SimpleForm>
    </Edit>
);

export default PaymentTransactionEdit;
