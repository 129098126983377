import { Datagrid, DateField, List, ReferenceField, TextField, WrapperField, TextInput } from "react-admin";

const filters = [
  <TextInput source="name" key="name"/>,
];

const PromoList = props => (
  <List {...props} filters={filters} exporter={false}>
    <Datagrid rowClick="edit">
      <TextField source="name" />
      <TextField source="campaignId" />
      <TextField source="visaProductId" />
      <TextField source="mcProductId" />
      <WrapperField label="Created" sortBy="createdAt">
        <ReferenceField source="createdBy" reference="users" />
        <DateField source="createdAt" showTime />
      </WrapperField>
      <WrapperField label="Updated" sortBy="updatedAt">
        <ReferenceField source="updatedBy" reference="users" />
        <DateField source="updatedAt" showTime />
      </WrapperField>
    </Datagrid>
  </List>
);

export default PromoList;
