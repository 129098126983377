import * as React from 'react';
import { useRecordContext, Button } from 'react-admin';
import { Link } from 'react-router-dom';
import { useCreatePath } from 'react-admin';
import { stringify } from 'query-string';
import ArchiveIcon from "@mui/icons-material/Archive";

const HistoryButton = () => {
    const record = useRecordContext();
    const createPath = useCreatePath();

    return (
        <Button
            size="small"
            component={Link}
            to={{
                pathname: createPath({ resource: 'payment_transaction_histories', type: 'list' }),
                search: stringify({
                    page: 1,
                    perPage: 25,
                    sort: 'id',
                    order: 'DESC',
                    filter: JSON.stringify({ uuid: `${record.uuid}` }),
                }),
            }}
        >
            <ArchiveIcon/>
        </Button>
    );
}

export default HistoryButton;