import { Edit, TopToolbar, SimpleForm, Toolbar, SaveButton, BooleanInput, TextInput } from "react-admin";

// Disabled default Show action
const MidEditActions = () => (
    <TopToolbar>
    </TopToolbar>
);

// Disabled Delete button
const MidEditFormActions = () => (
  <Toolbar>
    <SaveButton />
  </Toolbar>
);

const MidEdit = props => (
    <Edit {...props} actions={<MidEditActions />}>
        <SimpleForm toolbar={<MidEditFormActions />}>
            <TextInput source={"name"} />
            <TextInput source={"type"} />
            <BooleanInput source={"active"} />
            <BooleanInput source={"is3ds"} />
            <BooleanInput source={"upgradeTo3ds"} />
            <BooleanInput source={"downgradeToNon3ds"} />
        </SimpleForm>
    </Edit>
);

export default MidEdit;
