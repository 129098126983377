import { useRecordContext, Datagrid, DateField, List, BooleanField, FunctionField, NumberField, ReferenceField, ReferenceManyField, TextField, WrapperField, DateInput, TextInput } from 'react-admin';
//import { BulkUpdateFormButton } from "@react-admin/ra-form-layout";

import ReprocessButton from './ReprocessButton';
import HistoryButton from './HistoryButton';
import UpgradeTo3dsButton from './UpgradeTo3dsButton';
import DowngradeToNon3dsButton from './DowngradeToNon3dsButton';

const filters = [
    <TextInput source="uuid" key="uuid"/>,
    <TextInput source="buyer.email" key="buyer.email" label="Email"/>,
    <TextInput source="mid.id" key="mid.id"/>,
    <TextInput source="requestId" key="requestId"/>,
    <TextInput source="affiliateId" key="affiliateId"/>,
    <TextInput source="message" key="message"/>,
    <TextInput source="status" key="status"/>,
    <DateInput source="createdAt[after]" key="createdAt[after]" label="Created date from"/>,
    <DateInput source="createdAt[before]" key="createdAt[before]" label="Created date to"/>,
    <DateInput source="updatedAt[after]" key="updatedAt[after]" label="Updated date from"/>,
    <DateInput source="updatedAt[before]" key="updatedAt[before]" label="Updated date to"/>
];

const PaymentTransactionChildren = () => {    
    return (
        <ReferenceManyField reference='payment_transactions' source='id' target='parent' label='Reprocessed transactions'>
            <Datagrid bulkActionButtons={false}>
                <TextField/>
                <TextField/>
                <TextField/>
                <TextField source="uuid" />
                <TextField source="status" />
                <TextField source="message" />
                <NumberField source="amount" />
                <TextField source="currency" />
                <ReferenceField source="mid" reference={"mids"} />
                <TextField source="metadata.productId" label="ProductId"/>
                <TextField source="metadata.campaignId" label="CampaignId" />
                <TextField source="metadata.orderId" label="OrderId" />
                <WrapperField label="Created" sortBy="createdAt">
                    <ReferenceField source="createdBy" reference={"users"} />
                    <DateField source="createdAt" showTime />
                </WrapperField>
                {/*<WrapperField label="Updated" sortBy="updatedAt">*/}
                {/*    <ReferenceField source="updatedBy" reference={"users"} />*/}
                {/*    <DateField source="updatedAt" showTime />*/}
                {/*</WrapperField>*/}
            </Datagrid>
        </ReferenceManyField>
    );
};

const PaymentTransactionReprocessActions = () => {
    const record = useRecordContext();

    return (
        <>
        {record.reprocessable ? <ReprocessButton /> : null}
        {(false && record.reprocessable && record.is3ds) ? <DowngradeToNon3dsButton /> : null}
        {(false && record.reprocessable && !record.is3ds) ? <UpgradeTo3dsButton /> : null}
        </>
    );
}

const bulkActionButtons = () => {
    return (
        <>
        
        </>
    );
}

const PaymentTransactionList = props => (
    <List {...props} filters={filters} sort={{ field: 'createdAt', order: 'DESC' }}>
        <Datagrid rowClick="expand" expand={PaymentTransactionChildren} bulkActionButtons={bulkActionButtons} isRowExpandable={(record) => record?.childPaymentTransactions?.length > 0}>
            <TextField source="uuid" noWrap />
            <TextField source="buyer.email" label="Email" />
            <TextField source="status" />
            <TextField source="message" />
            <NumberField source="amount" />
            <TextField source="currency" />
            <ReferenceField source="mid" reference={"mids"} />
            <TextField source="metadata.productId" label="ProductId" />
            <TextField source="metadata.campaignId" label="CampaignId" />
            <TextField source="metadata.orderId" label="OrderId" />
            <FunctionField label="Reprocesses" render={record => `${record?.childPaymentTransactions?.length ?? ""}`} />
            <BooleanField source="is3ds"/>
            <WrapperField label="Created" sortBy="createdAt">
                <ReferenceField source="createdBy" reference={"users"} />
                <DateField source="createdAt" showTime />
            </WrapperField>
            {/*<WrapperField label="Updated" sortBy="updatedAt">*/}
            {/*    <ReferenceField source="updatedBy" reference={"users"} />*/}
            {/*    <DateField source="updatedAt" showTime />*/}
            {/*</WrapperField>*/}
            <WrapperField>
                <PaymentTransactionReprocessActions />
            </WrapperField>
            <WrapperField>
                <HistoryButton />
            </WrapperField>
        </Datagrid>
    </List>
);

export default PaymentTransactionList;
