import { ArrayField, Datagrid, DateField, EmailField, List, TextField, TextInput } from "react-admin";

const filters = [
  <TextInput source="email" key="email"/>,
  <TextInput source="apiTokens.token" key="apiTokens.token" label="Api token"/>
];

const UserList = (props) => (
  <List {...props} filters={filters} exporter={false}>
    <Datagrid rowClick="edit">
      <EmailField source="email" />
      <ArrayField source="apiTokens">
        <Datagrid bulkActionButtons={false}>
          <TextField source="token" />
          <DateField source="expireAt" />
        </Datagrid>
      </ArrayField>
    </Datagrid>
  </List>
);

export default UserList;
