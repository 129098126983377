import { BooleanField, Datagrid, DateField, List, ReferenceField, TextField, WrapperField, TextInput, BooleanInput, DateInput } from "react-admin";

const filters = [
  <TextInput source="merchantId" key="title"/>,
  <TextInput source="type" key="type"/>,
  <TextInput source="name" key="author"/>,
  <BooleanInput source="active" key="active"/>,
  <BooleanInput source="is3ds" key="3ds"/>,
  <BooleanInput source="upgradeTo3ds" key="upgradeTo3ds"/>,
  <BooleanInput source="downgradeToNon3ds" key="downgradeToNon3ds"/>,
  <DateInput source="createdAt[after]" key="createdAt[after]" label="Created date from"/>,
  <DateInput source="createdAt[before]" key="createdAt[before]" label="Created date to"/>,
  <DateInput source="updatedAt[after]" key="updatedAt[after]" label="Updated date from"/>,
  <DateInput source="updatedAt[before]" key="updatedAt[before]" label="Updated date to"/>
];

const MidList = props => (
  <List {...props} filters={filters} exporter={false}>
    <Datagrid rowClick="edit">
      <TextField source="merchantId" />
      <TextField source="name" />
      <TextField source="type" />
      <BooleanField source="active" />
      <BooleanField source="is3ds" />
      <BooleanField source="upgradeTo3ds" />
      <BooleanField source="downgradeToNon3ds" />
      <WrapperField label="Created" sortBy="createdAt">
        <ReferenceField source="createdBy" reference="users" />
        <DateField source="createdAt" showTime />
      </WrapperField>
      <WrapperField label="Updated" sortBy="updatedAt">
        <ReferenceField source="updatedBy" reference="users" />
        <DateField source="updatedAt" showTime />
      </WrapperField>
    </Datagrid>
  </List>
);

export default MidList;
