import { Edit, TopToolbar, SimpleForm, Toolbar, SaveButton, TextInput } from "react-admin";

// Disabled default Show action
const PromoEditActions = () => (
    <TopToolbar>
    </TopToolbar>
);

// Disabled Delete button
const PromoEditFormActions = () => (
  <Toolbar>
    <SaveButton />
  </Toolbar>
);

const PromoEdit = props => (
    <Edit {...props} actions={<PromoEditActions />}>
        <SimpleForm toolbar={<PromoEditFormActions />}>
            <TextInput source={"name"} />
        </SimpleForm>
    </Edit>
);

export default PromoEdit;
